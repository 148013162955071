import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
// import { install } from "ga-gtag";
import { theme } from "consts";
import AppProvider from "./AppProvider";
// import ReactPixel from "react-facebook-pixel";

import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

// install("G-01DY62ND5X", { send_page_view: false });

// ReactPixel.init("282478104410528");
// ReactPixel.pageView();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <AppProvider />
  </ChakraProvider>
);
